<template>
    <vx-card title="Purchase Order Approval Requests">
        <div class="flex vx-input-group">
            <vs-tabs>
                <vs-tab label="Waiting">
                    <tab-waiting></tab-waiting>
                </vs-tab>
                <vs-tab label="Approved">
                    <tab-approved></tab-approved>
                </vs-tab>
                <vs-tab label="Rejected">
                    <tab-rejected></tab-rejected>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabApproved from "./components/TabApproved.vue";
import TabRejected from "./components/TabRejected.vue";
import TabWaiting from "./components/TabWaiting.vue";
export default {
    components: {
        TabApproved,
        TabRejected,
        TabWaiting,
    },
    data() {
        return {
            activeTab: "Waiting",
        }
    },
    methods: {

    }
}
</script>
