<template>
    <div>
        <div class="mt-3 ml-1 vx-row">
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Warehouse</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/master/warehouses" label="name" track-by="name" @selected="(val) => {
                        this.selectedSrcWarehouse = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Supplier</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect base-url="/api/wms/v1/master/suppliers" label="name" track-by="name" @selected="(val) => {
                        this.selectedSupplier = val
                    }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>SR Type</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <multiselect
                        class="selectExample"
                        :multiple="false"
                        :select-label="''"
                        deselect-label=""
                        :placeholder="''"
                        :options="returnType"
                        label="name"
                        track-by="name"
                        :value="selectedType"
                    />
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="mt-3 vx-row">
            <div class="w-full vx-col mb-base">
                <Table :draw="draw" :status="activeTab" :transaction-date="transactionDate"
                    :source-warehouse-id="(this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.id : 0"
                    :supplier-id="(this.selectedSupplier)? this.selectedSupplier.id:0"
                    :ref-type="(this.selectedType)? this.selectedType.value.toString():''"
                    >
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import CustomMSelect from "./CustomMultiSelect.vue";
import Date from "./Date.vue";
import Table from "./WaitingTable.vue";
export default {
    components: {
        CustomMSelect,
        Date,
        Table
    },
    data() {
        return {
            returnType: [
                {
                    value: true,
                    name: "With reference",
                },
                {
                    value: false,
                    name: "Without reference",
                }
            ],
            activeTab: "Waiting",
            draw: 0,
            transactionDate: null,
            selectedSrcWarehouse: null,
            selectedSupplier: null,
            selectedType: null
        }
    },
    methods: {
        handleFilter() {
            this.draw++;
        },
    }
}
</script>